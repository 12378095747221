import App from '~app';

// new App({
//   name: 'my-app',
//   async setup(app) {
//     // const PluginName = (await import('/path/to/plugin')).default;
//     // app.use(plugin);
//     return app;
//   },
// });

new App({
  name: 'blue-search',
  async setup(app) {
    const BlueSearch = (
      await import(
        /* webpackMode: 'lazy' */
        '@bluemodus/blue-search'
      )
    ).default;
    app.use(BlueSearch.install, { el: this, options: this.props });
    return app;
  },
});
