const camelCase = s => s.replace(/-./g, x => x[1].toUpperCase());
class App {
  constructor(options) {
    if (options.directory) {
      options.directory = options.directory.trim();
      options.directory = options.directory.endsWith('/')
        ? options.directory.slice(0, -1)
        : options.directory;
      options.directory = options.directory.startsWith('/')
        ? options.directory.slice(1)
        : options.directory;
    }
    this.#registerVueApp(options);
  }
  #registerVueApp({
    name,
    directory,
    baseElement = HTMLElement,
    setup = app => Promise.resolve(app),
  }) {
    customElements.define(
      name,
      class extends baseElement {
        props = {};
        constructor() {
          super();
        }
        async connectedCallback() {
          const createApp = await import(
            /* webpackMode: "lazy" */
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            `vue`
          ).then(module => module.createApp);
          const app = await import(
            /* webpackMode: "lazy" */
            /* webpackPrefetch: true */
            /* webpackPreload: true */
            `../../${directory ? `${directory}/` : ''}apps/${name}/App.vue`
          ).then(module => module.default);

          this.props = this.getAttributeNames().reduce((props, name) => {
            if (!name.startsWith(':') && !name.startsWith('data-'))
              return props;
            let value = this.getAttribute(name);

            try {
              if (!isNaN(value)) value = Number(value);
              else if (value.toLowerCase() === 'true') value = true;
              else if (value.toLowerCase() === 'false') value = false;
              else value = JSON.parse(value);
            } finally {
              this.removeAttribute(name);
              name = camelCase(name.replace(':', '').replace('data-', ''));
              props[name] = value;
              this.removeAttribute(name);
              return props;
            }
          }, {});

          (await setup.call(this, createApp(app, this.props))).mount(this);
          this.setAttribute('component', '');
        }
      }
    );
  }
}

export { App, App as default };
